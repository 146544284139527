import { captureRemixErrorBoundaryError } from "@sentry/remix";
import Hotjar from '@hotjar/browser';
import * as Sentry from "@sentry/remix";

import { type LoaderFunctionArgs, json } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useMatches,
  useRouteError,
} from "@remix-run/react";
import { useEffect } from "react";
import { useChangeLanguage } from "remix-i18next/react";
import { useTranslation } from "react-i18next";
import i18next from "~/i18next.server";
// import { authenticate } from "./shopify.server";

export const loader = async ({ request }: LoaderFunctionArgs) => {
  // const { session } = await authenticate.admin(request);
  let locale = await i18next.getLocale(request);

  // console.log('root loader.');

  return json({
    // shopId: session.id,
    locale,
    ENV: {
      SENTRY_DSN: process.env.SENTRY_DSN,
      HOTJAR_SITE_ID: process.env.HOTJAR_SITE_ID,
      APP_VERSION: process.env.HEROKU_RELEASE_VERSION,
    },
  });
};

export let handle = {
  // In the handle export, we can add a i18n key with namespaces our route
  // will need to load. This key can be a single string or an array of strings.
  // TIP: In most cases, you should set this to your defaultNS from your i18n config
  // or if you did not set one, set it to the i18next default namespace "translation"
  i18n: "common",
};

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default function App() {

  const { ENV } = useLoaderData<typeof loader>();
  // Get the locale from the loader
  let { locale } = useLoaderData<typeof loader>();

  let { i18n } = useTranslation();

  // This hook will change the i18n instance language to the current locale
  // detected by the loader, this way, when we do something to change the
  // language, this locale will change and i18next will load the correct
  // translation files
  useChangeLanguage(locale);

  useEffect(() => {

    if (!ENV.SENTRY_DSN) return;

    Sentry.init({
      release: ENV.APP_VERSION,
      dsn: ENV.SENTRY_DSN,
      tracesSampleRate: 1,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1,

      // initialScope: {
      //   user: {id: shopId }
      // },
  
      integrations: [Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches
      }), ]
    });

    console.log(
      `🐛 init sentry client: ${ENV.APP_VERSION}`,
    );

  }, [
    // shopId,
    ENV.SENTRY_DSN,
    ENV.APP_VERSION,
  ]);

  useEffect(() => {

    if (!ENV.HOTJAR_SITE_ID) return;

    Hotjar.init(ENV.HOTJAR_SITE_ID, ENV.HOTJAR_VERSION);

    console.log(
      `🔬 init hotjar: ${ENV.HOTJAR_SITE_ID}`,
    );
  }, [
    ENV.HOTJAR_SITE_ID,
    ENV.HOTJAR_VERSION,
  ]);

  return (
    <html lang={locale} dir={i18n.dir()}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta name="shopify-debug" content="web-vitals" />
        <link rel="preconnect" href="https://cdn.shopify.com/" />
        <link
          rel="stylesheet"
          href="https://cdn.shopify.com/static/fonts/inter/v4/styles.css"
        />
        <Meta />
        <Links />
      </head>
      <body>
        <Outlet />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}